<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
      v-if="handleShowMenu(item.header, profileData.role)"
    />
  </ul>
</template>

<script>
import { mapGetters} from 'vuex'
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'
import MenuReportPermission from '@/common/permission/menu/report'
export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profileData: 'User/user',
    })
  },
  data () {
    return {
      menuReportPermission: MenuReportPermission,
      handleHeader: ['Report']
    }
  },
  methods: {
    handleShowMenu(header, role) {
      if(this.handleHeader.includes(header) && !this.menuReportPermission.includes(role)) {
        return false
      }
      return true
    }
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
}
</script>
<style scoped>
.text-black {
  color: #000
}
</style>